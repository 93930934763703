.storeWrapper {
  height: calc(100vw * .336);
  position: relative;
}

.storeWrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  height: calc(100vw * .336);
  width: 100%;
}

.eccComicsStoreBody {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 55%;
  height: 100%;
  top: 0;
  left: 80px;
}

.storeContent {
  top: 80px;
  left: 70px;
  position: absolute;

}

.storeContent h1,
.storeContent h3 {
  color: #fff;
}

.storeLocation {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.storeLocation .storeName {
  width: 110px;
  height: 28px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  color: #000;
}

@media only screen and (max-width:767px) {
  .storeWrapper {
    object-fit: fill;
  }

  .eccComicsStoreBody {
    left: 0;
    height: 55%;
    top: 30%;
    width: 50%;
  }

  .storeContent {
    top: 15px;
    left: 30px;
  }

  .storeContent h3 {
    font-size: 12px;
    margin-bottom: 0;
  }

  .storeContent h1 {
    font-size: 14px;
    margin-bottom: 0;
  }

  .storeLocation .storeName {
    width: 51px;
    height: 15px;
    font-size: 10px;
  }

  .storeLocation {
    margin-top: 10px;
  }
}

@media only screen and (max-width:550px) {
  .storeContent{
    top: 10px;
  }
  .storeContent h3{
    font-size: 10px;
  }
  .storeContent h1{
    font-size: 13px;
  }
  .eccComicsStoreBody{
    top: 20%;
    height: 60%;
    width: 55%;
  }
  .storeLocation .storeName {
    font-size: 9px;
  }
  .storeContent{
    top: 10px;
  }
}
@media only screen and (max-width:450px) 
{
  .storeContent{
    left: 15px;
  }
  .storeLocation{
    gap: 5px;
  }
}

@media only screen and (max-width:400px) {
  .storeContent{
    left: 10px;
  }
  .storeContent h3{
    font-size: 9px;
  }
  .storeContent h1{
    font-size: 12px;
  }
  .eccComicsStoreBody{
    height: 70%;
    top: 15%;
    width: 65%;
  }
  .storeLocation .storeName {
    font-size: 8px;
  }
}