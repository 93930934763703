.newsLetterWrapper{
  position: relative;
}
.newsLetter{
  background-color: #000;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.socialMediaWrapper{
  position: absolute;
  background-color: #000;
  right: 0px;
  width: 200px;
}
.socialMediaList{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  height: 45px;
  gap: 15px;
}
.socialMediaList .socialMediaIcon{
  color: #fff;
}
.socialMediaList .socialMediaIcon{
  font-size: 16px !important;
}

@media only screen and (max-width:767px) {
  .socialMediaWrapper{
    background-color: transparent;
    bottom: 10px;
  }
  .newsLetter{
    height: 250px;
  }
}
