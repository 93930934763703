.eccComicsDiscountsBody{
    margin-bottom: 50px;
}
.discountWrapper{
    display: flex;
    flex-direction: row;
  gap: 30px; 
  width: 100%;
  box-sizing: border-box; 
}
.discountContainer {
    flex: 1;
    box-sizing: border-box; 
  }
  .discountContainer img {
    height: 250px;
    width: 100%;
    display: block; 
    max-width: 100%;
  }
  @media only screen and (max-width:767px) 
  {
    .discountWrapper
    {
      display: block;
    }
    .discountContainer{
      margin-top: 20px;
      height: auto;
    }
  }