.eccComicsNewsBody {
    margin-bottom: 60px;
}

.eccComicsNewsBody .newsHeading {
    font-size: 30px;
    font-weight: 500;
    color: #000;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.productListingWrapper {
    width: 100% !important;
}

.productListingWrapper .productMeta h1 {
    font-size: 11px;
    margin-top: 15px;
    font-weight: normal;
    height: 40px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: normal;
    margin-bottom: 0;
}

.productLinkWrapper .imgWrapper img {
    width: 100%;
}

.productPrice {
    font-weight: 700;
    font-size: 14px;
    color: #000;
    margin-bottom: 0;
    width: 100%;
    text-align: center;
}

.buyButton {
    width: 100%;
    display: flex;
    justify-content: center;
}

.buyButton button {
    border: 1px solid #000;
    font-size: 13px;
}

@media only screen and (max-width:767px) {
    .eccComicsNewsBody .newsHeading {
        font-size: 20px;
        font-weight: 500;
    }
}